import { createSlice } from "@reduxjs/toolkit"

export const robotSlice = createSlice({
    name: 'robot',
    initialState: {
        robotId: 0,
        showSidebar: false,
        robotStatusMessage: null,
        hasAccess: false,
        colorMode: false,
        autoCenterOn: true,
        darkMode: true,
        teleopCommands: [],
        fpsValues: [],
        autoRotationOn: false,
    },
    reducers: {
        setRobot: (state, action) => {
            state.robotId = action.payload
            state.showSidebar = "responsive"
        },
        unsetRobot: (state) => {
            state.robotId = 0
            state.showSidebar = false
            state.robotStatusMessage = null
        },
        setRobotStatusMessage: (state, action) => {
            state.robotStatusMessage = action.payload
        },
        setColorMode: (state, action) => {
            state.colorMode = action.payload
        },
        setAutoCenter: (state, action) => {
            state.autoCenterOn = action.payload
        },
        addTeleopCommand: (state, action) => {
            state.teleopCommands.push(action.payload)
        },
        resetTeleopCommands: (state) => {
            state.teleopCommands = []
        },
        addFps: (state, action) => {
            state.fpsValues.push(action.payload)
        },
        resetFps: (state) => {
            state.fpsValues = []
        },
        setDarkMode: (state, action) => {
            state.darkMode = action.payload
        },
        setAutoRotation: (state, action) => {
            state.autoRotationOn = action.payload
        }
    }
})

export const {
        setRobot,
        unsetRobot,
        setRobotStatusMessage,
        setColorMode,
        setAutoCenter,
        addTeleopCommand,
        resetTeleopCommands,
        addFps,
        resetFps,
        setDarkMode,
        setAutoRotation
    } = robotSlice.actions

export default robotSlice.reducer