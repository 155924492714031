import { authenticationService } from './authentication.service';

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.token) {
        return {  Authorization: `Bearer ${currentUser.token}`, 'Accept': 'application/json',
        'Content-Type': 'application/json' };
    } else {
        return {};
    }
}

export function authHeaderFormData() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.token) {
        return {
        'Authorization': `Bearer ${currentUser.token}`,
        'Accept': 'application/json'
    };
    } else {
        return {};
    }
}

export function freeHeader() {
   
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
    
}
