import { authHeader,authHeaderFormData, freeHeader } from '../account/auth-header';
import AppSettings from '../AppSettings';

export default function postData(method = '', data = {}, keepAlive = false) {
  // Default options are marked with *
  return fetch(AppSettings.ServiceUrl + method, {
    keepalive: keepAlive,
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    credentials: 'include', // include, *same-origin, omit
    headers: authHeader(),
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
}

export function postFormData(method = '', formData = {}) {
  // Default options are marked with *
  return fetch(AppSettings.ServiceUrl + method, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    credentials: 'include', // include, *same-origin, omit
    headers: authHeaderFormData(),
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: formData // body data type must match "Content-Type" header
  });
}


export function postWithoutHeader(method = '', data = {}) {
    // Default options are marked with *
    return fetch(AppSettings.ServiceUrl + method, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        credentials: 'omit', // include, *same-origin, omit
        headers: freeHeader(),
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
}

export function getMethod(method) {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return fetch(AppSettings.ServiceUrl + method, requestOptions);
}