import { combineReducers } from 'redux'
import sidebar from './Sidebar';
import modal from './Modal';
import manualSidebarReducer from '../state/manualSidebarSlice'
import robotReducer from '../state/robotSlice'
import hubConnectionReducer from '../state/hubConnectionSlice';
export default combineReducers({
    sidebar: sidebar,
    modal: modal,
    manualSidebar: manualSidebarReducer,
    robot: robotReducer,
    hubConnections: hubConnectionReducer
});