export const  Notifiers = {
    Store:"store",
    Purchaser:"purchaser",
    Operator:"operator",
    RobotAlert:"robotalert",
    ShowAlert:"showalert",
    ShowErrorNotify: "shownotifyalert"
}


export default Notifiers;