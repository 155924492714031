import ActionTypes from '../ActionTypes'

const initialState = {
    //modalType: null,
    title: "Alert",
    show: false,
    message: ""
}


export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ShowModal:
            return {
                title: action.title,
                show: true,
                message: action.message
            }
        case ActionTypes.HideModal:
            return initialState
        default:
            return state
    }
}