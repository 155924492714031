import { trackPromise } from 'react-promise-tracker';
import SendAlert, { MessageTypes } from './SendAlert';

export default function callMethod(serviceMethod, parameter, showSuccessMessage = false, showFailMessage = true, isTrackPromise=true) {
    if (!isTrackPromise) {
        return new Promise(function (myResolve, myReject) {
            serviceMethod(parameter).then(
                (response) => {
                    if(response.isSuccess) {
                        showSuccessMessage && SendAlert(response.errorMessage, MessageTypes.Success)
                        myResolve(response.data)
                    } else {
                        failHandler(showFailMessage);
                    }
                    function failHandler(showFailMessage){
                        if (showFailMessage)
                            SendAlert("There is a problem. " + (response.errorMessage ?? response.message))
                        myReject(response.errorMessage)
                    }
                },
                (error) => {
                    let errorMsg = "Error: " + (error.message ?? error) + ": " + serviceMethod.name;
                    SendAlert(errorMsg);
                    myReject(errorMsg)
                }
            )
        })
    }
    return trackPromise(new Promise(function (myResolve, myReject) {
        serviceMethod(parameter).then(
            (response) => {
                if(response.isSuccess) {
                    showSuccessMessage && SendAlert(response.errorMessage, MessageTypes.Success)
                    myResolve(response.data)
                } else {
                    failHandler(showFailMessage);
                }
                function failHandler(showFailMessage){
                    if (showFailMessage)
                        SendAlert("There is a problem. " + (response.errorMessage ?? response.message))
                    myReject(response.errorMessage)
                }
            },
            (error) => {
                let errorMsg = "Error: " + (error.message ?? error) + ": " + serviceMethod.name;
                SendAlert(errorMsg);
                myReject(errorMsg)
            }
        )
    })); 
}