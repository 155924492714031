import Emitter from "../services/Emitter";
import Notifiers from '../datamodels/Notifiers';

export const MessageTypes = {
    Error: "Error!",
    Success: "Success",
    Warning: "Warning"
}

export default function SendAlert(message, messageType = MessageTypes.Error) {
    Emitter.emit(Notifiers.ShowAlert, { message, messageType });
}

export function SendErrorMessage(message, messageType, disableTranslate = false, isComp=false) {
    Emitter.emit(Notifiers.ShowErrorNotify, { message, messageType, disableTranslate, isComp });
}
