import React from 'react';
import { CForm, CCol, CLabel, CFormGroup } from '@coreui/react';
import FormModal from '../../containers/modals/FormModal';

class ForgetOrderCodeModal extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <FormModal closeOnBackdrop noFooter title={this.props.t("orderPageLogin.forgetOrderCodeTitle")} show closeModal={this.props.closeModal}  >
                <CForm action="" method="post" className="form-horizontal">
                    <CFormGroup row>
                        <CCol>
                            <CLabel>
                                {this.props.t("orderPageLogin.forgetOrderCode")}
                            </CLabel>
                        </CCol>
                    </CFormGroup>
                </CForm>
            </FormModal>
        );
    }
}
export default ForgetOrderCodeModal;