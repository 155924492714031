import React from 'react';
import Alert from 'react-bootstrap/Alert';

function AlertNotify(props) {
    return (
        <>
        <Alert style={{ opacity: props.show ? 1 : 0}} show={true} onClose={() => props.onClose()} variant='danger' closeLabel='Close Alert' dismissible
            fade="false">
            {props.message}
        </Alert>
        </>
    )
}


export default AlertNotify;
