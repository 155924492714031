import { BehaviorSubject } from 'rxjs';
import AppSettings from '../AppSettings';
import { handleLoginResponse, handleResponse } from './handle-response';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    evriLogin,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`${AppSettings.ServiceUrl}/account/login`, requestOptions)
        .then(handleLoginResponse)
        .then(resp => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(resp.data));
            currentUserSubject.next(resp.data);
            return resp.data;
        });
}

function evriLogin(user) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    currentUserSubject.next(user);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}
