import { CButton, CModal, CModalHeader, CModalBody, CModalFooter, CTooltip, CInput, CForm } from '@coreui/react';
import React, { useState } from "react";
import SendAlert from '../../helper/SendAlert';
import { useTranslation } from "react-i18next";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
export default function FormModal(props) {
    const { t } = useTranslation('translation');
    const [validated, setValidated] = useState(false)

    const handleValidate = () => {
        props.handleValidate().then(() => {
            setValidated(true)
        }).finally(() => {
            setValidated(true)
        })
    }

    function handleSubmit(e) {
        e.preventDefault();
        props.submitMethod().then(
            resp => {
                if (resp.isSuccess) {
                    props.onSuccess(resp.data);
                }
                else {
                    SendAlert(t("FormModal.Problem") + resp.errorMessage);
                    if (props.onFail != null)
                        props.onFail();
                }
            },
            error => {
                SendAlert(t("FormModal.Error") + error.message ?? error);
                if (props.onFail != null)
                    props.onFail();
            }
        )
    }

    if (props.triggerSubmit) {
        handleSubmit();
    }

    return (
        <>
            <CModal addContentClass={props.contentClass} closeOnBackdrop={props.closeOnBackdrop ?? false} size={props.size} color="info" show={props.show} onClose={props.closeModal} >
                {props.noHeader ? null : <CModalHeader className={props.danger ? "bg-danger" : "bg-info"} closeButton><b>{props.title}</b> </CModalHeader>}
                <CModalBody style={props.overflowYDisable ? null : {
                    maxHeight: 'calc(100vh - 200px)',
                    overflowY: 'auto'
                }}>
                    <CForm id="myform" onSubmit={handleSubmit} className="form-horizontal">
                        {props.children}
                    </CForm>
                </CModalBody>
                {props.noFooter ? null : <CModalFooter>
                    <CButton color="danger" onClick={props.closeModal}>{props.closeButtonText ? props.closeButtonText : t("FormModal.Close")}</CButton>
                    {!props.noSaveButton && (!props.validateButton || validated) ?
                        <>
                            {props.disabled && props.disableText ?
                                <OverlayTrigger
                                    placement="bottom"
                                    // show={props.disabled}
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="button-tooltip">{props.disableText}</Tooltip>
                                    }
                                >
                                    <CButton
                                        onClick={props.disabled ? null : handleSubmit}
                                        color="success"
                                        disabled={props?.disabled}
                                    >
                                        {props.saveButtonText ? props.saveButtonText : t("FormModal.Save")}
                                    </CButton>
                                </OverlayTrigger>
                                :
                                <CButton
                                    onClick={props.disabled ? null : handleSubmit}
                                    color="success"
                                // disabled={props.disabled}
                                >
                                    {props.saveButtonText ? props.saveButtonText : t("FormModal.Save")}
                                </CButton>
                            }
                        </>
                        : !props.noSaveButton ?
                            <CButton
                                color="primary"
                                disabled={props.disabled}
                                onClick={handleValidate}
                            >
                                {t("FormModal.Validate")}
                            </CButton>
                            : !props.yesButton ?
                                <CButton
                                    color="success"
                                    onClick={props.onYes}
                                >
                                    {t("FormModal.Yes")}
                                </CButton>
                                : null
                    }
                </CModalFooter>}
            </CModal>
        </>
    )

}