import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { authenticationService } from './authentication.service';
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLink,
  CRow,
  CTooltip
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import AlertNotify from "../helper/AlertNotify";

function Login(props) {
  const history = useHistory();
  const [username, setusername] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [showMessage, setShowMessage] = useState(false);

  function passwordChange(event) {
    setPassword(event.target.value);
  }
  function usernameChange(event) {
    setusername(event.target.value);
  }

  function login() {
    authenticationService.login(username, password)
      .then(
        user => {
          const { from } = props.location.state || { from: { pathname: "/" } };
          history.push(from);
        },
        error => {
          setShowMessage(true);
      
          setErrorMessage(error.message??error);
          // setSubmitting(false);
          // setStatus(error);
        }
      );
  }
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Login</h1>
                    <CLink className="float-right" to="/evri/login">
                      <CTooltip content="EVRI Customer?">
                        <CInputGroupText>
                          <CIcon name="cil-cursor" />
                        </CInputGroupText>
                      </CTooltip>
                    </CLink>
                    <p className="text-muted">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput onChange={usernameChange} type="username" name="username" placeholder="username" autoComplete="username" />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput onChange={passwordChange} type="password" name="password" placeholder="Password" autoComplete="current-password" />
                    </CInputGroup>
                    <CRow>
                      <CCol xs="6">
                        <CButton onClick={login} color="primary" className="px-4">Login</CButton>
                      </CCol>
                      <CCol xs="6" className="text-right">
                        <CTooltip content="Please contact us." placement="right-start" >
                          <CButton color="link" className="px-0">Forgot password?</CButton>
                        </CTooltip>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
                <CCardFooter>
                  <AlertNotify message={errorMessage} show={showMessage} onClose={()=>setShowMessage(false)} />
                </CCardFooter>
              </CCard>
              {/* <CCard className="text-white bg-primary py-5 d-md-down-none" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua.</p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>Register Now!</CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard> */}
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
