import { createSlice } from "@reduxjs/toolkit"

export const showManualSidebarSlice = createSlice({
    name: 'showManualSidebar',
    initialState: {
        minimized: true,
    },
    reducers: {
        show: (state) => {
            state.minimized = false
        },
        hide: (state) => {
            state.minimized = true
        }
    }
})

export const { show, hide } = showManualSidebarSlice.actions

export default showManualSidebarSlice.reducer