import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// Importing the Bootstrap CSS
//import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/style.scss';


import { Provider } from 'react-redux'
import store from './redux/store'
import { icons } from './assets/icons'
import { register } from './helper/serviceWorker';

React.icons = icons
ReactDOM.render(
    <Provider store={store}>
        <App robotId="1" />
    </Provider>, 
    document.getElementById('root')
);
register();