import { createSlice } from "@reduxjs/toolkit"

export const hubConnectionSlice = createSlice({
    name: 'hubConnection',
    initialState: {
        connections: [],
    },
    reducers: {
        addConnection: (state, action) => {
            state.connections.push(action.payload)
        },
        removeConnection: (state, action) => {
            let connection = state.connections.find(x => x.connectionId == action.payload.connectionId)
            if (connection) {
                try{ connection.stop() } catch(e) {}
            }
            state.connections = state.connections.filter(x => x.connectionId != action.payload.connectionId)
            
        },
        removeAllConnections: (state) => {
            state.connections.forEach(connection => {
                try{ connection.stop() } catch(e) {}
            });
            state.connections = []
        }
    }
})

export const { addConnection, removeConnection, removeAllConnections } = hubConnectionSlice.actions

export default hubConnectionSlice.reducer