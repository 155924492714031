import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CContainer,
    CForm,
    CImg,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import AlertNotify from "../../helper/AlertNotify";
import callMethod from "../../helper/CallMethod"
import { useTranslation } from 'react-i18next';
import ForgetOrderCodeModal from "./ForgetOrderCodeModal";
import { isRobotCodeValid } from "../../services/RobotService";

const OrderPageLogin = (props) => {
    const history = useHistory()
    const [orderCode, setOrderCode] = useState(null)
    const [errorMessage, setErrorMessage] = useState()
    const [showMessage, setShowMessage] = useState(false)
    const [showForgetOrderCode, setShowForgetOrderCode] = useState(false)
    const [trackButtonDisabled, setTrackButtonDisabled] = useState(true)
    const [robotCodeValid, setRobotCodeValid] = useState(false)

    const { t } = useTranslation()
    const robotCode = props.match.params.robotCode

    useEffect(() => {
        callMethod(isRobotCodeValid, robotCode).then((isValid) => {
            setRobotCodeValid(isValid)
            if (!isValid) {
                setShowMessage(true);
                setErrorMessage(t("orderPageLogin.invalidRobotCode"))
            }
        })
    }, [t, robotCode])

    useEffect(() => {
        setTrackButtonDisabled(!robotCodeValid || orderCode == null || String(orderCode?.trim()) == "")
    }, [orderCode, robotCodeValid])

    const onInputChange = (event) => {
        setOrderCode(event.target.value)
    }

    const handleTrackOrder = (e) => {
        e.preventDefault()
        history.push("/spitz/" + robotCode + "/" + orderCode);
    }

    useEffect(() => {
        if (history.location.state?.wrongCode) {
            setShowMessage(true);
            setErrorMessage(t("orderPageLogin.wrongLoginInfo") + " " + history.location.state.message)
        }
    }, [history.location.state, t])

    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="5">
                        <CCard style={{width: "100%", borderRadius: "40px"}}>
                            <CCardBody>
                                <h1 className="text-center">{t("orderPageLogin.title")}</h1>
                                <CForm onSubmit={handleTrackOrder}>
                                    <div className="text-center">
                                        <CImg src="img/robotImg.jpeg" width="60%" />
                                    </div>
                                    <p className="text-muted">{t("orderPageLogin.info")}</p>
                                    <CInputGroup className="mb-3"><CInputGroupPrepend><CInputGroupText><CIcon name="cilFastfood" /></CInputGroupText></CInputGroupPrepend>
                                        <CInput onChange={onInputChange} name="orderCode" placeholder={t("orderPageLogin.placeholder")} />
                                    </CInputGroup>
                                    <CRow>
                                        <CCol xs="8">
                                            <CButton onClick={() => setShowForgetOrderCode(true)} color="link" className="px-0">{t("orderPageLogin.help")}</CButton>
                                        </CCol>
                                        <CCol xs="4" className="text-right">
                                            <CButton
                                                disabled={trackButtonDisabled}
                                                type="submit"
                                                color="success"
                                                style={{cursor: trackButtonDisabled ? 'not-allowed' : 'pointer'}}
                                            >
                                                {t("orderPageLogin.button")}
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                </CForm>
                                <br />
                                <AlertNotify message={errorMessage} show={showMessage} onClose={() => setShowMessage(false)} ></AlertNotify>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </CContainer>
            {showForgetOrderCode && 
                <ForgetOrderCodeModal t={t} closeModal={() => setShowForgetOrderCode(false)} />}
        </div>
    )
}

export default OrderPageLogin